var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-flex container-fluid mobi d-none d-lg-none d-xl-block search px-4"},[_c('b-row',[_c('b-col',{attrs:{"cols":"8","lg":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-around",staticStyle:{"display":"flex","gap":"1px","flex-wrap":"wrap"}},[_c('span',[_c('b-nav-item-dropdown',{ref:"more",attrs:{"id":"dropdown-1","text":_vm.$t('general.all')}},[_c('hr',{staticStyle:{"margin-top":"-10px","background-color":"red","height":"3px","width":"40%","float":"left"}}),_c('br'),_c('div',[_c('b-row',_vm._l((_vm.categories.slice(6)),function(category,index){return _c('b-col',{key:index,staticClass:"padding:50px",attrs:{"cols":"6"}},[_c('b-dropdown-item',{staticClass:"ml-1",on:{"click":() => {
                      _vm.$emit('onChangeCategoryName', category.category.name);
                      _vm.bcategory({ cat_id: category.category.id });
                      _vm.$emit('activate:matching:category', null);
                      _vm.$emit('activateSuggestion', category.category.name);
                      _vm.$emit('update:keyword', {
                        keyword: category.category.name,
                        cat_id: category.category.id
                      });
                    }}},[_vm._v(" "+_vm._s(category.category.name)+" ")])],1)}),1)],1)])],1),_vm._l((_vm.categories.slice(0, 10)),function(category,index){return _c('div',{key:index,staticClass:"cursor-pointer custom"},[_c('div',{staticClass:"search-items",on:{"click":() => {
                    _vm.showSubCat(category.category, category.sub_cat);
                    _vm.bcategory({ cat_id: category.category.id });
                    _vm.$emit('activate:matching:category', null);
                    _vm.$emit('activateSuggestion', category.category.name);
                  }}},[_vm._v(" "+_vm._s(category.category.name)+" ")])])})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }